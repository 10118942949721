
import * as Api from "@/types/graphql";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Card from "@/components/Card.vue";
import Btn from "@/components/Btn.vue";
import Modal from "@/components/Modal.vue";
import CourseOverview from "@/components/CourseOverview.vue";
import { State } from "vuex-class";
import { State as StateStore } from "../store";
import { getCourseTypeLabel, getAvailabilityLabel, CourseType } from "@/utils/courses";
import { formatDateFromApi } from "@/plugins/moment";

@Component({
  components: { Card, Btn, Modal, CourseOverview },
})
export default class AddCourseOverview extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;

  loading = false

  @Prop()
  courseData;

  courseType = CourseType;
  courseOverview = null;
  showConfirmationModal = null;

  @Watch("courseData")
  handleCourseData(val) {
    this.courseOverview = val;
  }

  mounted() {
    this.courseOverview = this.courseData;
  }

  getPermission() {
    return getAvailabilityLabel(this.courseOverview.availability);
  }

  getType() {
    return getCourseTypeLabel(this.courseOverview.type);
  }

  getLessonDateAndTime(lesson) {
    if (lesson.date == null) {
      return "";
    }
    let dateTime = formatDateFromApi(lesson.date, 'DD/MM/YYYY');
    if (lesson.startTime && lesson.endTime) {
      dateTime =
        dateTime + " dalle " + lesson.startTime + " alle " + lesson.endTime;
    }
    return dateTime;
  }

  next() {
    this.$emit("next");
    this.loading = true
  }

  back() {
    this.$emit("back");
  }
}
