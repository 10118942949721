
import * as Api from "@/types/graphql";
import { Component, Prop, Vue } from "vue-property-decorator";
import Card from "@/components/Card.vue";
import Btn from "@/components/Btn.vue";
import ManageLessonModal from "@/components/ManageLessonModal.vue";
import { Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import _ from "lodash";
import { formatDateFromApi } from "@/plugins/moment";

@Component({
  components: { Card, Btn, ManageLessonModal },
})
export default class AddCourseManageLessons extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;

  @Prop({
    default: () => {
      return [];
    },
  })
  teachers;

  manageLessonModalKey = 0;
  showManageLessonModal = null;

  lessonToEdit = null;
  lessonToEditIndex = -1;
  lessons = [];

  showAddLessonModal() {
    this.lessonToEdit = null;
    this.lessonToEditIndex = -1;
    this.manageLessonModalKey = Math.random();
    this.showManageLessonModal = true;
  }

  editLesson(lesson, index) {
    this.lessonToEdit = _.clone(lesson);
    this.lessonToEditIndex = index;
    this.manageLessonModalKey = Math.random();
    this.showManageLessonModal = true;
  }

  next() {
    this.$emit("next", this.lessons);
  }

  back() {
    this.$emit("back");
  }

  formatDate(date) {
    return formatDateFromApi(date, "dddd DD MMMM YYYY");
  }

  removeLesson(index) {
    this.lessons.splice(index, 1);
  }

  getTeacherName(guid){
    const teacher = _.find(this.teachers, {guid})
    if(_.isNil(teacher)){
      return '';
    } else {
      return teacher.name + ' ' + teacher.surname
    }
  }

  saveLesson(lesson) {
    if (this.lessonToEditIndex >= 0) {
      this.lessons[this.lessonToEditIndex] = _.clone(lesson);
    } else {
      this.lessons.push(_.clone(lesson));
    }
    this.lessons = _.sortBy(this.lessons, ["date", "startTime"]);
    this.lessonToEditIndex = -1;
    this.lessonToEdit = null;
  }
}
