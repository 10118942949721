
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import DatePicker from "@/components/DatePicker.vue";
import Modal from "@/components/Modal.vue";
import NumberSelector from "@/components/NumberSelector.vue";
import TimePicker from "@/components/TimePicker.vue";
import { formatDateFromApi, getToday } from "@/plugins/moment";
import * as Api from "@/types/graphql";
import { LESSON_ERROR } from "@/utils/courses";
import { getDayTimes } from "@/utils/time";
import WithValidation from "@/utils/validation";
import gql from "graphql-tag";
import _, { indexOf } from "lodash";
import moment from "moment";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";

@Component({
  components: { Card, Btn, Modal, NumberSelector, DatePicker, TimePicker },
})
export default class ManageLessonModal extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @Mutation showSuccess;
  @Mutation showError;
  @Action checkLessonTimeAvailability;

  loading = false;

  @Prop({
    default: () => {
      return [];
    },
  })
  teachers;
  @Prop()
  lessonToEdit;
  @Prop()
  courseGuid;
  @Prop()
  lessons;

  $refs!: {
    lessonForm: WithValidation;
  };

  @Watch("lesson.date")
  handleLessonDate(val) {
    if (moment(val, "YYYY-MM-DD").isSame(moment(), "day")) {
      // date selected is today
      const nowMoment = moment();

      getDayTimes().forEach((time) => {
        if (this.startTime == getDayTimes()[0]) {
          const timeMoment = moment(time, "HH:mm");
          if (timeMoment.isAfter(nowMoment)) {
            this.startTime = timeMoment.format("HH:mm");
          }
        }
      });
    } else {
      this.startTime = getDayTimes()[0];
    }
  }

  nameRules = [(v) => !!v || "Inserire il titolo della lezione"];
  teacherRules = [];
  dateRules = [(v) => !!v || "Inserire la data della lezione"];
  startTimeRules = [(v) => !!v || "scegliere l'ora di inizio"];
  endTimeRules = [(v) => !!v || "scegliere l'ora di fine"];

  isFormValid = false;

  startTime = getDayTimes()[0];

  lesson = {
    title: null,
    teachers: [],
    date: null,
    startTime: null,
    endTime: null,
    description: null,
  };

  mounted() {
    if (_.isNil(this.lessonToEdit)) {
      this.resetValues();
    } else {
      if (this.isSavedLesson()) {
        this.lesson = {
          title: this.lessonToEdit.lesson_title,
          teachers: this.lessonToEdit.teacher_guid,
          date: this.lessonToEdit.lesson_date,
          startTime: this.lessonToEdit.lesson_from,
          endTime: this.lessonToEdit.lesson_to,
          description: this.lessonToEdit.lesson_info,
        };
      } else {
        this.lesson = this.lessonToEdit;
      }
    }
  }

  isEditing() {
    return !_.isNil(this.lessonToEdit);
  }

  isSavedLesson() {
    if (this.lessonToEdit) {
      return this.lessonToEdit["lesson_guid"] != undefined;
    } else return false;
  }

  resetValues() {
    this.$refs.lessonForm?.reset();
    this.lesson = {
      title: null,
      teachers: [],
      date: null,
      startTime: null,
      endTime: null,
      description: null,
    };
  }

  getDefaultDate() {
    return getToday();
  }

  getDateAndTime() {
    if (this.lesson.date == null) {
      return "";
    }
    let dateTime = formatDateFromApi(this.lesson.date, "DD/MM/YYYY");
    if (this.lesson.startTime && this.lesson.endTime) {
      dateTime =
        dateTime +
        " dalle " +
        this.lesson.startTime +
        " alle " +
        this.lesson.endTime;
    }
    return dateTime;
  }

  async saveLesson() {
    if (this.$refs.lessonForm.validate()) {
      if (this.isSavedLesson()) {
        await this.updateLesson();
        this.$emit("update");
      } else {
        if (this.courseGuid) {
          await this.storeLesson();
        } else {
          this.loading = true;
          const start = moment(this.lesson.startTime + ":00", "HH:mm:ss");
          const end = moment(this.lesson.endTime + ":00", "HH:mm:ss");
          const checkAvailability = await this.checkLessonTimeAvailability({
            startTime: this.lesson.date + " " + this.lesson.startTime + ":00",
            duration: end.diff(start, "minutes"),
          });
          if (checkAvailability) {
            this.$emit("save", this.lesson);
            this.onClose(true);
          } else {
            this.showError(
              "Non è possibile aggiungere la lezione - orario non disponibile"
            );
          }
          this.loading = false;
        }
      }
    }
  }

  onClose(reset) {
    if (reset) {
      this.resetValues();
    }
    this.$emit("close");
  }

  async updateLesson() {
    const updateLessonRes = await this.$apollo.mutate({
      mutation: gql`
        mutation updateEducationLesson(
          $lessonGuid: ID
          $title: String
          $info: String
          $teacherGuid: [String]
        ) {
          updateEducationLesson(
            lesson_guid: $lessonGuid
            lesson_title: $title
            lesson_info: $info
            teacher_guid: $teacherGuid
          )
        }
      `,
      variables: {
        lessonGuid: this.lessonToEdit["lesson_guid"],
        title: this.lesson.title,
        info: this.lesson.description,
        teacherGuid: this.lesson.teachers
      },
    });
    if (updateLessonRes.data.updateEducationLesson) {
      this.showSuccess("Lezione modificata correttamente");
      this.onClose(true)
    } else {
      this.showError("Errore durante modifica lezione");
    }
  }

  async storeLesson() {
    this.loading = true;
    const createLessonRes = await this.$apollo.mutate({
      mutation: gql`
        mutation insertEducationLesson(
          $courseGuid: String
          $order: Int
          $title: String
          $info: String
          $teacherGuid: [String]
          $date: String
          $startTime: String
          $endTime: String
        ) {
          insertEducationLesson(
            course_guid: $courseGuid
            orderby: $order
            lesson_title: $title
            lesson_info: $info
            teacher_guid: $teacherGuid
            lesson_date: $date
            lesson_from: $startTime
            lesson_to: $endTime
          )
        }
      `,
      variables: {
        courseGuid: this.courseGuid,
        order: 0,
        title: this.lesson.title,
        info: this.lesson.description,
        teacherGuid: this.lesson.teachers,
        date: this.lesson.date,
        startTime: this.lesson.startTime,
        endTime: this.lesson.endTime,
      },
    });

    if (createLessonRes.data.insertEducationLesson == LESSON_ERROR) {
      this.showError(
        "Errore durante inserimento della lezione - orario non disponibile"
      );
    } else {
      if (createLessonRes.data.insertEducationLesson) {
        this.showSuccess("Lezione inserita correttamente");
        this.$emit("update");
        this.onClose(true);
      } else {
        this.showError("Errore durante inserimento della lezione");
      }
    }

    this.loading = false;
  }

  getLessonMinDate() {
    return getToday();
  }
}
