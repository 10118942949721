
import _ from "lodash";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class NumberSelector extends Vue {
  @Prop()
  label: string;

  @Prop()
  initialValue: number;

  @Watch("value")
  onValueChanged(val: string, oldVal: string) {
    if (_.isNil(val) || val == "") {
      this.value = 0;
    } else {
      this.$emit("changed", parseInt(val));
    }
  }

  mounted(){
    this.value = this.initialValue;
  }

  value = 0;
  valueChanged = false;

  showLabel() {
    return !_.isNil(this.label) && !this.valueChanged;
  }

  decrement() {
    if (this.value > 0) {
      this.value--;
      this.valueChanged = true;
    }
  }

  increment() {
    this.value++;
    this.valueChanged = true;
  }
}
