
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import NumberSelector from "@/components/NumberSelector.vue";
import SelectedUsers from "@/components/SelectedUsers.vue";
import UsersSelector from "@/components/UsersSelector.vue";
import * as Api from "@/types/graphql";
import { Component, Vue, Watch } from "vue-property-decorator";
import { State } from "vuex-class";
import { State as StateStore } from "../store";
import * as _ from "lodash";
import {
  courseAvailabilityOptions,
  CourseType,
  courseTypeOptions,
} from "../utils/courses";

@Component({
  components: { Card, Btn, NumberSelector, UsersSelector, SelectedUsers },
})
export default class AddCourseStep1 extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @State((state: StateStore) => state.data.selectedUsers)
  selectedUsers: Api.User;

  @Watch("selectedUsers")
  onUsersSelected() {
    this.courseInfo.teachers = _.sortBy(this.selectedUsers, "name");
  }

  isFormValid = false;

  courseType = CourseType;

  nameRules = [(v) => !!v || "Inserire il titolo del corso"];
  areaRules = [(v) => !!v || "Inserire un area tematica"];
  availabiltyRules = [(v) => !!v || "Selezionare un tipo di permesso"];
  teachersRules = [];
  typeRules = [(v) => !!v || "Selezionare una modalità"];

  // nameRules = [];
  // areaRules = [];
  // availabiltyRules = [];
  // teachersRules = [];
  // typeRules = [];

  courseTypeOptions = courseTypeOptions;
  courseAvailabilityOptions = courseAvailabilityOptions;

  courseInfo = {
    name: null,
    area: null,
    classes: 0,
    hours: 0,
    minPeople: 0,
    maxPeople: 0,
    type: null,
    availability: null,
    link: null,
    classroom: null,
    address: null,
    location: null,
    teachers: null,
  };

  next() {
    if (
      (
        this.$refs.addCourseStep1Form as Vue & {
          validate: () => boolean;
        }
      ).validate()
    ) {
      this.$emit("next", this.courseInfo);
    }
  }
}
