
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { getDayTimes } from "../utils/time";

@Component({})
export default class TimePicker extends Vue {
  @Prop({ default: () => [] })
  rules;
  @Prop({ default: "Seleziona orario" })
  label: string;
  @Prop({ default: false })
  disabled: boolean;
  @Prop({ default: null })
  startTime: string;
  @Prop({ default: null })
  preSelectedTime: string;

  @Watch("startTime")
  handleStartTime(val) {
    if (val != null) {
      const timeIndex = getDayTimes().findIndex((time) => time == this.startTime);
      this.times = getDayTimes().slice(timeIndex + 1);
    }
  }

  @Watch("preSelectedTime")
  handlePreSelectedTime(val) {
    if (val != null) {
      this.time = val;
    }
  }

  times = getDayTimes();
  time = null;

  timeSelected() {
    this.$emit("selected", this.time);
  }
}
