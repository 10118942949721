
import * as Api from "@/types/graphql";
import { Component, Prop, Vue } from "vue-property-decorator";
import Card from "@/components/Card.vue";
import Btn from "@/components/Btn.vue";
import { State } from "vuex-class";
import { State as StateStore } from "../store";
import { v4 as uuidv4 } from "uuid";
import WithValidation from "@/utils/validation";

@Component({
  components: { Card, Btn },
})
export default class AddCourseMoreInfo extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;

  $refs!: {
    descriptionForm: WithValidation;
  };

  isFormValid = false;

  moreInfo = null;

  next() {
    this.$emit("next", this.moreInfo);
  }

  back() {
    this.$emit("back");
  }
}
