
import Modal from "@/components/Modal.vue";
import * as Api from "@/types/graphql";
import { env, EnvVars } from "@/utils/env";
import { RoleCodes } from "@/utils/roles";
import { getSessionToken } from "@/vue-apollo";
import gql from "graphql-tag";
import _ from "lodash";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { Getter, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";

@Component({
  components: {
    Modal,
  },
  watch: {},
})
export default class DownloadReportModal extends Vue {
  @Mutation showSuccess;
  @Mutation showError;

  @Prop()
  lessons;

  @Prop()
  courseGuid;

  lessonsToShow = [];

  selected = "ALL";

  loading = false;

  mounted() {
    this.lessonsToShow = [];
    const allLessonsItem = {};
    allLessonsItem["lesson_title"] = "Tutte le lezioni";
    allLessonsItem["lesson_guid"] = "ALL";
    this.lessonsToShow.push(allLessonsItem);
    this.lessons.forEach((lesson) => {
      this.lessonsToShow.push(lesson);
    });
  }

  async download() {
    this.loading = true;

    const token = await getSessionToken();

    const headers = { "x-access-token": token };

    fetch(
      `${env()[EnvVars.VUE_APP_BE]}/education/course/report/${
        this.courseGuid
      }/${this.selected}`,
      { headers }
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = 'report.xlsx';
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
    this.loading = false;
  }

  onClose() {
    this.$emit("close");
  }
}
