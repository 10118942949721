
import * as Api from "@/types/graphql";
import { Component, Vue } from "vue-property-decorator";
import AddCourseInfo from "@/components/AddCourseInfo.vue";
import AddCourseManageLessons from "@/components/AddCourseManageLessons.vue";
import AddCourseDescription from "@/components/AddCourseDescription.vue";
import AddCourseMoreInfo from "@/components/AddCourseMoreInfo.vue";
import AddCourseOverview from "@/components/AddCourseOverview.vue";
import Btn from "@/components/Btn.vue";
import { Action, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import { getDayTimes } from "../utils/time";
import _ from "lodash";
import { RoutesNames } from "@/router";
import gql from "graphql-tag";
import { CourseStatus } from "@/utils/courses";

@Component({
  components: {
    Btn,
    AddCourseInfo,
    AddCourseManageLessons,
    AddCourseDescription,
    AddCourseMoreInfo,
    AddCourseOverview,
  },
})
export default class AddCourseView extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @Mutation showSuccess;
  @Mutation showError;

  loading = false;
  currentStep = 1;

  selectedTeachers = [];
  courseData = null;

  back() {
    if (this.currentStep > 1) {
      this.currentStep--;
    }
  }

  next(step, data) {
    if (step == 1) {
      this.courseData = { ...this.courseData, ...data };
      this.courseData.lessons = [];
      this.setSelectedTeachers();
    }
    if (step == 2) {
      this.courseData = { ...this.courseData, lessons: data };
    }
    if (step == 3) {
      this.courseData = {
        ...this.courseData,
        description: data.description,
        moreInfo: data.moreInfo,
      };
    }
    if (step == 4) {
      this.saveCourse();
    } else {
      this.currentStep++;
    }
  }

  setSelectedTeachers() {
    if (!_.isNil(this.courseData)) {
      if (_.isArray(this.courseData.teachers)) {
        this.selectedTeachers = _.sortBy(this.courseData.teachers, 'name');
      }
    }
  }

  async saveCourse() {
    this.loading = true;
    const createCourseRes = await this.$apollo.mutate({
      mutation: gql`
        mutation insertEducationCourse(
          $ownerGuid: String
          $title: String
          $lessons: Int
          $minStudents: Int
          $maxStudents: Int
          $type: String
          $room: String
          $address: String
          $location: String
          $hours: Int
          $availability: String
          $link: String
          $status: String
          $subjectarea: String
          $info: String
          $moreInfo: String
          $teachers: [UserInput]
        ) {
          insertEducationCourse(
            owner_guid: $ownerGuid
            course_title: $title
            course_lessons: $lessons
            course_students_min: $minStudents
            course_students_max: $maxStudents
            course_type: $type
            course_room: $room
            course_address: $address
            course_location: $location
            course_hours: $hours
            course_availability: $availability
            course_link: $link
            course_status: $status
            course_subjectarea: $subjectarea
            course_info: $info
            course_info_more: $moreInfo
            teachers: $teachers
          )
        }
      `,
      variables: {
        ownerGuid: this.user.guid,
        title: this.courseData.name,
        lessons: this.courseData.classes,
        minStudents: this.courseData.minPeople,
        maxStudents: this.courseData.maxPeople,
        type: this.courseData.type,
        room: this.courseData.classroom,
        address: this.courseData.address,
        location: this.courseData.location,
        hours: this.courseData.hours,
        availability: this.courseData.availability,
        link: this.courseData.link,
        status: CourseStatus.Published,
        subjectarea: this.courseData.area,
        info: this.courseData.description,
        moreInfo: this.courseData.moreInfo,
        teachers: this.courseData.teachers.map((teacher) => {
          return { guid: teacher.guid };
        }),
      },
    });
    if (createCourseRes.data.insertEducationCourse) {
      this.saveLessons(createCourseRes.data.insertEducationCourse);
    } else {
      this.loading = false;
      this.showError("Errore durante inserimento corso");
    }
  }

  async saveLessons(courseGuid) {
    let lessonCount = 0;
    for (const lesson of this.courseData.lessons) {
      lessonCount++;
      const createLessonRes = await this.$apollo.mutate({
        mutation: gql`
          mutation insertEducationLesson(
            $courseGuid: String
            $order: Int
            $title: String
            $info: String
            $teacherGuid: [String]
            $date: String
            $startTime: String
            $endTime: String
          ) {
            insertEducationLesson(
              course_guid: $courseGuid
              orderby: $order
              lesson_title: $title
              lesson_info: $info
              teacher_guid: $teacherGuid
              lesson_date: $date
              lesson_from: $startTime
              lesson_to: $endTime
            )
          }
        `,
        variables: {
          courseGuid: courseGuid,
          order: lessonCount,
          title: lesson.title,
          info: lesson.description,
          teacherGuid: lesson.teachers,
          date: lesson.date,
          startTime: lesson.startTime,
          endTime: lesson.endTime,
        },
      });
    }
    if (lessonCount == this.courseData.lessons.length) {
      this.loading = false;
      this.showSuccess("Corso inserito correttamente");
      this.$router.push({
        name: RoutesNames.coursesDetails,
        params: { courseGuid: courseGuid },
        query: { back: "false" },
      });
    } else {
      this.loading = false;
      this.showError("Errore durante inserimento delle lezioni");
    }
  }
}
