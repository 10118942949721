
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import EditCourseModal from "@/components/EditCourseModal.vue";
import DownloadReportModal from "@/components/DownloadReportModal.vue";
import Modal from "@/components/Modal.vue";
import { formatDateFromApi } from "@/plugins/moment";
import {
  CourseType,
  getAvailabilityLabel,
  getCourseDuration,
  getCourseTypeLabel,
} from "@/utils/courses";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import * as _ from "lodash";

@Component({
  components: { Card, Btn, Modal, EditCourseModal, DownloadReportModal },
})
export default class CourseOverview extends Vue {
  @Prop()
  courseData;
  @Prop({ default: true })
  showLessons;
  @Prop({ default: false })
  showEditButton;

  courseType = CourseType;
  course = null;
  showConfirmationModal = null;
  showEditCourseModal = null;
  modalKey = null;
  courseToEdit = null;
  sectionToEdit = "mainInfo";

  showReportModal = null;

  @Watch("courseData")
  handleCourseData(val) {
    if (_.isNil(val.teachers)) {
      val.teachers = [];
    }
    this.course = val;
  }

  mounted() {
    this.course = this.courseData;
  }

  getPermission() {
    return getAvailabilityLabel(this.course.availability);
  }

  getType() {
    return getCourseTypeLabel(this.course.type);
  }

  getLessonDateAndTime(lesson) {
    if (lesson.date == null) {
      return "";
    }
    let dateTime = formatDateFromApi(lesson.date, "DD/MM/YYYY");
    if (lesson.startTime && lesson.endTime) {
      dateTime =
        dateTime + " dalle " + lesson.startTime + " alle " + lesson.endTime;
    }
    return dateTime;
  }

  getTeacherName(guid){
    const teacher = _.find(this.course.teachers, {guid})
    if(_.isNil(teacher)){
      return '';
    } else {
      return teacher.name + ' ' + teacher.surname
    }
  }

  openEditCourseModal(sectionToEdit) {
    this.sectionToEdit = sectionToEdit;
    this.courseToEdit = _.clone(this.course);
    this.modalKey = Math.random();
    this.showEditCourseModal = true;
  }

  update() {
    this.$emit("update");
  }

  getCourseHours() {
    return getCourseDuration(this.course);
  }

  openReportModal() {
    this.modalKey = Math.random();
    this.showReportModal = true;
  }
}
