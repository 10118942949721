export function getDayTimes(): string[] {
  const MIN_HOUR = 7;
  const MAX_HOUR = 21;
  const values: string[] = [];
  for (let hour = MIN_HOUR; hour <= MAX_HOUR; hour++) {
    const minutes = ["00", "15", "30", "45"];
    minutes.forEach((min) => {
      values.push(String(hour).padStart(2, '0') + ":" + min);
    });
  }

  return values;
}
