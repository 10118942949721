
import Modal from "@/components/Modal.vue";
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import NumberSelector from "@/components/NumberSelector.vue";
import SelectedUsers from "@/components/SelectedUsers.vue";
import UsersSelector from "@/components/UsersSelector.vue";
import * as Api from "@/types/graphql";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import {
  courseAvailabilityOptions,
  CourseType,
  courseTypeOptions,
} from "../utils/courses";
import { parseDateFromApi } from "@/plugins/moment";
import { checkUserRole, RoleCodes } from "@/utils/roles";
import { associateData } from "@/utils/user";
import gql from "graphql-tag";
import _ from "lodash";
import WithValidation from "@/utils/validation";

@Component({
  components: {
    Modal,
    Card,
    Btn,
    NumberSelector,
    UsersSelector,
    SelectedUsers,
  },
  watch: {},
})
export default class EditCourseModal extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @State((state: StateStore) => state.data.selectedUsers)
  selectedUsers: Api.User[];
  @Mutation showSuccess;
  @Mutation showError;
  @Mutation setSelectedUsers;

  $refs!: {
    editCourseForm: WithValidation;
  };

  classes = null;

  isFormValid = false;
  allowSave = true;

  @Prop()
  courseInfo;

  @Prop({default: true})
  showMainInfo;
  
  @Prop({default: false})
  showDescription;

  @Prop({default: false})
  showMoreInfo;

  courseType = CourseType;
  courseTypeOptions = courseTypeOptions;
  courseAvailabilityOptions = courseAvailabilityOptions;

  nameRules = [(v) => !!v || "Inserire il titolo del corso"];
  areaRules = [(v) => !!v || "Inserire un area tematica"];
  availabiltyRules = [(v) => !!v || "Selezionare un tipo di permesso"];
  teachersRules = [(v) => v.length > 0 || "Selezionare almeno un docente"];
  typeRules = [(v) => !!v || "Selezionare una modalità"];

  mounted() {
    if (this.courseInfo) {
      this.setSelectedUsers(this.courseInfo.teachers);
    }
  }

  onClose() {
    this.allowSave = false;
    this.$emit("close");
  }

  async updateCourse() {
    if (this.$refs.editCourseForm.validate()) {
      const res = await this.$apollo.mutate({
        mutation: gql`
          mutation course_update(
            $guid: ID
            $owner: String
            $title: String
            $lessons: Int
            $minStudents: Int
            $maxStudents: Int
            $type: String
            $room: String
            $address: String
            $location: String
            $hours: Int
            $availability: String
            $subjectarea: String
            $info: String
            $moreInfo: String
            $teachers: [UserInput]
          ) {
            updateEducationCourse(
              course_guid: $guid
              owner_guid: $owner
              course_title: $title
              course_lessons: $lessons
              course_students_min: $minStudents
              course_students_max: $maxStudents
              course_type: $type
              course_room: $room
              course_address: $address
              course_location: $location
              course_hours: $hours
              course_availability: $availability
              course_subjectarea: $subjectarea
              course_info: $info
              course_info_more: $moreInfo
              teachers: $teachers
            )
          }
        `,
        variables: {
          guid: this.courseInfo.guid,
          ownerGuid: this.courseInfo.owner,
          title: this.courseInfo.name,
          lessons: this.courseInfo.classes,
          minStudents: this.courseInfo.minPeople,
          maxStudents: this.courseInfo.maxPeople,
          type: this.courseInfo.type,
          room: this.courseInfo.classroom,
          address: this.courseInfo.address,
          location: this.courseInfo.location,
          hours: this.courseInfo.hours,
          availability: this.courseInfo.availability,
          subjectarea: this.courseInfo.area,
          info: this.courseInfo.description,
          moreInfo: this.courseInfo.moreInfo,
          teachers: this.selectedUsers.map((teacher) => {
            return { guid: teacher.guid };
          }),
        },
      });

      if (res.data.updateEducationCourse) {
        this.$emit("update", this.courseInfo);
        this.showSuccess("Corso modificato correttamente");
        this.onClose();
      } else {
        this.showError("Errore durante modifica corso");
      }
    }
  }
}
